import React, { useState } from 'react';
import AppProvider from './contexts/AppContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Login from './views/Login';
import userSession from './utils/userSession';
import NotFound from './views/NotFound';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(userSession());
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [apiName, setApiName] = useState('nv_route');
  const [center, setCenter] = useState([43.733518788890734,7.418609261512757]);
  const [shape, setShape] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [showShape, setShowShape] = useState({ id: 0 });
  const [showInstructionsBar, setShowInstructionsBar] = useState(false);
  const [showFormBar, setShowFormBar] = useState(false);
  const [originSide, setOriginSide] = useState("left");
  const [destinationSide, setDestinationSide] = useState("left");
  const [responseFormat, setResponseFormat] = useState("simplified");
  const [shiftedPolyline, setshiftedPolyline] = useState("false");
  const [language, setLanguage] = useState("fr");
  return (
    <AppProvider
      value={{
        isAuthenticated, setIsAuthenticated,
        center, setCenter,
        shape, setShape,
        instructions, setInstructions,
        showInstructionsBar, setShowInstructionsBar,
        showFormBar, setShowFormBar,
        showShape, setShowShape,
        origin, setOrigin,
        destination, setDestination,
        apiName, setApiName,
        originSide, setOriginSide,
        destinationSide, setDestinationSide,
        responseFormat, setResponseFormat,
        shiftedPolyline, setshiftedPolyline,
        language, setLanguage
      }}
    >
      <BrowserRouter>
        <Routes>
          {
            isAuthenticated ?
              <Route path='/' element={<Home />} /> :
              <>
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/login' element={<Login />} />
              </>
          }
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  )
}

export default App;