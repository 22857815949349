import React from 'react';
import RouteForm from '../../components/RouteForm';
import { deleteSession } from '../../utils/userSession';
import { useValues } from '../../contexts/AppContext';
import NVibeLogoWhite from '../../assets/Logo N-Vibe White.png';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
const Navbar = () => {
    const { showInstructionsBar, setShowInstructionsBar, showFormBar, setShowFormBar } = useValues();
    const handleShowInstructionsBar = () => setShowInstructionsBar(!showInstructionsBar);
    const handleShowFormBar = () => setShowFormBar(!showFormBar);
    const { isAuthenticated, setIsAuthenticated } = useValues();
    const navigateTo = useNavigate();
    const logout = () => {
        deleteSession();
        navigateTo('/login');
        setIsAuthenticated(false);
    }
    return (
        <Container
            fluid
            style={{
                background: 'rgba(5,60,117,255)',
                position: 'fixed',
                minHeight: "80px",
                zIndex: 1001,

            }}>
            <Row>
                <Col md={1} lg={1} className='d-flex align-items-center'>
                    <Image src={NVibeLogoWhite} alt='logo N-Vibe' style={{ height: 'calc(100% - 10px)', width: 'calc(100% - 10px)' }} />
                </Col>
                {
                    isAuthenticated &&
                    <>
                        <Col>
                            <Row className='d-flex align-items-center' >
                                <Col lg={2} md={2} className='text-center'>
                                    <Button variant="secondary" size="sm" onClick={handleShowInstructionsBar}
                                    >
                                        {!showInstructionsBar ? 'Show instructions' : 'Hide'}
                                    </Button>
                                </Col>
                                <Col>
                                    <RouteForm />
                                </Col>
                                <Col lg={2} md={2} className='text-center'>
                                    <Button variant="secondary" size="sm" onClick={handleShowFormBar}
                                    >
                                        {!showFormBar ? 'More options' : 'Hide'}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1} lg={1} className='d-flex align-items-center'>
                            <Button variant='danger' size="sm" onClick={logout}>Logout</Button>
                        </Col>
                    </>
                }
            </Row>
        </Container>
    );

}

export default Navbar;